import Vue from "vue";
import VueRouter from "vue-router";
import localStorageUtils from "@/utils/localStorageUtils";
import menuStorage from '@/utils/menuStorage'

Vue.use(VueRouter);

// 基础路由配置（白名单路由）
const constantRoutes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/noAccess",
    name: "noAccess",
    component: () => import("@/views/noAccess.vue"),
  },
  {
    path: "*",
    name: "notfound",
    component: () => import("@/views/NotFound.vue"),
  }
]

// 需要权限的路由容器
const asyncRouteContainer = {
  path: "/",
  component: () => import("@/components/LayoutView.vue"),
  children: []
}

const router = new VueRouter({
  routes: constantRoutes
});

// 生成动态路由配置
function generateAsyncRoutes(menuList) {
  return menuList.map(menu => {
    const route = {
      path: menu.path,
      name: menu.name,
      icon: menu.icon,
      component: () => import('@/components/MainView'),
      children: []
    }
    
    if (menu.list && menu.list.length > 0) {
      route.children = menu.list.map(subMenu => ({
        path: subMenu.path,
        name: subMenu.name,
        component: loadComponent(subMenu.path),
        meta: {
          title: subMenu.name,
          icon: subMenu.icon
        }
      }))
    }
    
    return route
  })
}

// 动态加载组件
function loadComponent(path) {
  const componentMap = {
    'messageList': () => import('@/views/controlPanel/StudentList.vue'),
    'feedbackList': () => import('@/views/controlPanel/feedbackList.vue'),
    'complaintList': () => import('@/views/controlPanel/complaintList.vue'),
    'infomanage': () => import('@/views/controlPanel/InfoManage.vue'),
    'infoManageList': () => import('@/views/controlPanel/infoManageList.vue'),
    'equipmentBrandEntity': () => import('@/views/controlPanel/equipmentBrandEntity.vue'),
    'servePersonnelManage': () => import('@/views/controlPanel/servePersonnelManage.vue')
  }
  return componentMap[path] || (() => import('@/views/NotFound.vue'))
}

// 导出 initDynamicRoutes 函数
export function initDynamicRoutes() {
  const menuList = menuStorage.getMenuList()
  if (menuList && menuList.length > 0) {
     // 先清除所有动态添加的路由
     resetRouter()
    asyncRouteContainer.children = generateAsyncRoutes(menuList)
    router.addRoute(asyncRouteContainer)
    // router.addRoutes([asyncRouteContainer])
  }
}
// 重置路由
function resetRouter() {
  const newRouter = new VueRouter({
    routes: constantRoutes
  })
  router.matcher = newRouter.matcher
}
// 路由守卫
router.beforeEach((to, from, next) => {
  const token = localStorageUtils.get("token")
  
  // 白名单直接放行
  if (['/login', '/noAccess', '/404'].includes(to.path)) {
    next()
    return
  }
  
  if (!token) {
    next('/login')
    return
  }
  
  if (to.path === '/login') {
    next('/')
    return
  }
  
  const menuList = menuStorage.getMenuList()
  if (!menuList || menuList.length === 0) {
    next('/login')
    return
  }
  
  // 检查访问权限
  if (checkPermission(to.path, menuList)) {
    next()
  } else {
    next('/noAccess')
  }
})

// 权限检查
function checkPermission(path, menuList) {
  if (path === '/') return true
  
  return menuList.some(menu => {
    if (menu.path === path) return true
    
    if (menu.list && menu.list.length) {
      return menu.list.some(subMenu => {
        const fullPath = `${menu.path}/${subMenu.path}`
        return path === fullPath
      })
    }
    return false
  })
}

// 初始化动态路由
initDynamicRoutes()

export default router
