import Vue from "vue";
import App from "./App.vue";
import "@/assets/styles/reset.css"; // 引入重置样式
import router from "./router"; // 引入路由
import store from "./store";

// 本地存储
import local_storage from "./utils/localStorageUtils";
Vue.prototype.$local_storage = local_storage;

// 引入Element组件
import ElementComponents from "./plugins/element-components";
Vue.use(ElementComponents);

Vue.config.productionTip = false;

// 生产环境下禁用所有 console 输出
if (process.env.NODE_ENV === 'production') {
  console.log = console.warn = console.error = function () {};  
}

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
