export default {
  // 保存菜单数据
  setMenuList(menuList) {
    sessionStorage.setItem('menuList', JSON.stringify(menuList))
  },

  // 获取菜单数据
  getMenuList() {
    const menuList = sessionStorage.getItem('menuList')
    return menuList ? JSON.parse(menuList) : []
  },

  // 清除菜单数据
  clearMenuList() {
    sessionStorage.removeItem('menuList')
  }
} 